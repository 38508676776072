import React from 'react'
import Typography from '@material-ui/core/Typography'

const Error = () => (
  <Typography variant='body1'>
    Error :(
  </Typography>
)

export default Error
